import { DISTRIBUTION_ERROR_MESSAGES } from '@core/services/distribution/form/validation/distribution-error-messages';
import {
  FF_NGX_DATE_ERROR_MESSAGES,
  FF_NGX_FILE_UPLOAD_ERROR_MESSAGES,
  FF_NGX_USER_FORM_ERROR_MESSAGES,
  FfNgxControlErrorMessages
} from "@fagforbundet/ngx-components";

export type ErrorName = 'unknown';

export const controlErrorMessages: FfNgxControlErrorMessages<ErrorName> = {
  ...FF_NGX_USER_FORM_ERROR_MESSAGES,
  ...FF_NGX_FILE_UPLOAD_ERROR_MESSAGES,
  ...FF_NGX_DATE_ERROR_MESSAGES,
  ...DISTRIBUTION_ERROR_MESSAGES,
  unknown: ({ where }) => `Det skjedde en uvented feil ${where ?? ''}`,
};
