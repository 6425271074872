import {
  FfNgxAuthBundleConfig,
  FfNgxUrlHelper,
  getUrlFromEndpointDefinition,
} from '@fagforbundet/ngx-components';
import { HttpContextToken } from '@angular/common/http';
import { idApiNoAuthEndpoints } from '@config/apis/id/id-api.config';

export const REQUEST_DOES_NOT_REQUIRE_AUTH = new HttpContextToken<boolean>(
  () => true,
);

export const authBundleConfig: FfNgxAuthBundleConfig = {
  debug: false,
  authorize: {
    requestRequiresAuth: (request) => {
      let requiresAuth = true;

      if (
        request.context.has(REQUEST_DOES_NOT_REQUIRE_AUTH) &&
        request.context.get(REQUEST_DOES_NOT_REQUIRE_AUTH)
      ) {
        return false;
      }

      const hasMatch = idApiNoAuthEndpoints.find((url) => {
        return FfNgxUrlHelper.isMatch(
          getUrlFromEndpointDefinition(url).toString(),
          request.url,
        );
      });

      if (hasMatch) {
        return false;
      }

      if (new RegExp(/^\/?assets\/.*/).test(request.url)) {
        requiresAuth = false;
      }

      return requiresAuth;
    },
  },
};
