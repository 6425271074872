import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/public/public.routes'),
  },
  {
    path: '',
    loadChildren: () => import('./modules/authenticated/authenticated.routes'),
    canActivate: [],
    canActivateChild: [],
    canMatch: [],
  },
  { path: 'unauthorized', redirectTo: '/' },
];
