import {
  APP_INITIALIZER,
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeNb from '@angular/common/locales/nb';
import {
  AbstractSecurityStorage,
  authInterceptor,
  AuthModule,
} from 'angular-auth-oidc-client';
import { authnConfig } from '@config/authn.config';
import { loadingScreenConfig } from '@config/loading-screen.config';
import { provideRouterWithConfig } from '@config/router.config';
import { authBundleConfig } from '@config/auth-bundle.config';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  apiConfigurationProvider,
  requestBundleConfig,
} from '@config/request-bundle.config';
import { storageBundleConfig } from '@config/storage-bundle.config';
import { controlErrorMessages } from '@config/control-error.config';
import { TitleStrategy } from '@angular/router';
import { PageTitleStrategy } from '@core/title-strategies/page-title-strategy';
import { cookieConsentServiceFactory } from '@core/factories/cookie-consent-service-factory';
import { CookieConsentService } from '@core/services/cookie-consent.service';
import { initializeInMemoryStorageFactory } from '@core/factories/in-memory-store-initializer.factory';
import { CookiebotConfig } from '@config/cookiebot.config';
import { piwikProConfig } from '@config/piwik-pro.config';
import {
  ffNgxAuthBundleProvider,
  FfNgxAuthOidcCustomStore,
  FfNgxDateParserFormatter,
  ffNgxFormErrorProvider,
  ffNgxLoadingScreenProvider,
  ffNgxPiwikProProvider,
  ffNgxRequestsBundleProvider,
  ffNgxStorageBundleProvider,
  FfNgxStorageService,
  NgbDateParserFormatter,
} from '@fagforbundet/ngx-components';
import { ngxCookiebotProvider } from '@halloverden/ngx-cookiebot';

registerLocaleData(localeNb);

export const appConfig: ApplicationConfig = {
  providers: [
    apiConfigurationProvider(),
    ffNgxAuthBundleProvider(authnConfig, authBundleConfig),
    ffNgxFormErrorProvider(controlErrorMessages),
    ffNgxLoadingScreenProvider(loadingScreenConfig),
    ffNgxRequestsBundleProvider(requestBundleConfig),
    ffNgxStorageBundleProvider(storageBundleConfig),
    importProvidersFrom(AuthModule),
    ngxCookiebotProvider(CookiebotConfig),
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor()])),
    provideRouterWithConfig(),
    ffNgxPiwikProProvider(piwikProConfig),
    {
      provide: AbstractSecurityStorage,
      useClass: FfNgxAuthOidcCustomStore,
    },
    { provide: LOCALE_ID, useValue: 'nb' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'NOK',
    },
    {
      provide: NgbDateParserFormatter,
      useClass: FfNgxDateParserFormatter,
    },
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeInMemoryStorageFactory,
      multi: true,
      deps: [FfNgxStorageService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: cookieConsentServiceFactory,
      deps: [CookieConsentService],
      multi: true,
    },
  ],
};
