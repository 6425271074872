import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import idApiConfig from '@config/apis/id/id-api.config';
import { distributionApiConfig } from '@config/apis/distribution/distribution-api.config';
import { membershipApiConfig } from '@config/apis/membership/membership-api.config';
import { environment } from '@environments/environment';
import { notificationApiConfig } from '@config/apis/notification/notification-api.config';
import { FF_NGX_REQUEST_BUNDLE_API_CONFIGS, FfNgxRequestBundleConfig } from "@fagforbundet/ngx-components";

export const requestBundleConfig: FfNgxRequestBundleConfig = {
  ttl: 1000 * 60 * 5,
  debug: environment.requestBundle.debug,
};

export function apiConfigurationProvider(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: FF_NGX_REQUEST_BUNDLE_API_CONFIGS,
      useValue: [
        idApiConfig,
        distributionApiConfig,
        membershipApiConfig,
        notificationApiConfig
      ],
      multi: true,
    },
  ]);
}
