import { EnvironmentProviders } from '@angular/core';
import { environment } from '@environments/environment';
import {
  provideRouter,
  withComponentInputBinding,
  withDebugTracing,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { routes } from '../app.routes';

export function provideRouterWithConfig(): EnvironmentProviders {
  if (environment.router.debug) {
    return provideRouter(
      routes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload',
      }),
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'top', anchorScrolling: 'enabled' }),
      withDebugTracing(),
    );
  }
  return provideRouter(
    routes,
    withRouterConfig({
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    }),
    withComponentInputBinding(),
    withInMemoryScrolling({ scrollPositionRestoration: 'top', anchorScrolling: 'enabled' }),
  );
}
