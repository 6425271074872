import { StoreName } from '@core/enums/store-name.enum';
import { FfNgxStorageService } from "@fagforbundet/ngx-components";

export function initializeInMemoryStorageFactory(
  storageService: FfNgxStorageService,
) {
  return (): void => {
    Object.values(StoreName as object).forEach((storeName: string) => {
      storageService.useInMemoryStorage(storeName);
    });
  };
}
